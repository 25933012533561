import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    currentColor: { type: String, default: "#ffffff" },
    colorImages: { type: Object, default: {} },
    currentViewId: { type: String, default: "" },
    currentViewData: { type: Object, default: {} }
  }

  static outlets = [
    "blanks--self-design--canvas",
    "blanks--self-design--gallery-thumbnail"
  ]

  static targets = [
    "saveForm"
  ]

  connect() {
    this.currentViewDataValue = this.colorImagesValue[this.currentColorValue][this.currentViewIdValue];
  }

  currentViewIdValueChanged(value, previousValue) {
    if (value == '' || value === previousValue) {
      return false
    }

    this.setCurrentCanvas(value);
    this.setCurrentThumbnail(value);
  }

  addArtwork(url) {
    this.currentCanvas.addArtwork(url);
  }

  updateCurrentColor(color) {
    this.currentColorValue = color;
    this.currentViewDataValue = this.colorImagesValue[this.currentColorValue][this.currentViewIdValue];
  }

  currentViewDataValueChanged(value, previousValue) {
    setTimeout(function() {
      // When color change, we update all background of all canvas
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        Object.entries(this.colorImagesValue[this.currentColorValue]).forEach(([viewId, data]) => {
          if (viewId == outlet.currentViewIdValue) {
            outlet.updateBackgroundImage(this.colorImagesValue[this.currentColorValue][viewId], viewId);
          }
        });
      }.bind(this));
    }.bind(this), 0);
  }

  updateCurrentViewId(data, blankViewId) {
    this.currentViewIdValue = blankViewId;
    this.currentViewDataValue = data;
  }

  currentColorValueChanged(value, previousValue) {
    if (value == '' || value === previousValue) {
      return false
    }

    setTimeout(function() {
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        outlet.updateCurrentColor(value);
      });

      this.blanksSelfDesignGalleryThumbnailOutlets.forEach(function(outlet) {
        outlet.updateCurrentColor(value);
      });
    }.bind(this), 0);
  }

  setCurrentCanvas(value) {
    setTimeout(function() {
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        if (outlet.currentViewIdValue == value) {
          this.currentCanvas = outlet;
        }
      }.bind(this));
    }.bind(this), 0);
  }

  save()
  {
    const blobs = [];
    this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
      let dt = outlet.canvas.toDataURL({
        format: 'png',
        quality: 1.0
      });
      let blob = this.dataURItoBlob(dt);
      blobs.push(blob);
    }.bind(this));

    // Reverse the order of blobs
    blobs.reverse();

    // Clear existing files from the input
    const fileInput = this.saveFormTarget.querySelector('input[type="file"]');
    fileInput.value = '';

    // Attach blobs in reverse order
    blobs.forEach((blob, index) => {
      this.attachBlobToFileInput(blob, `image${index + 1}.png`, fileInput);
    });

    this.saveFormTarget.submit();
  }

  attachBlobToFileInput(blob, fileName, inputElement) {
    // Crée un fichier à partir du Blob
    const file = new File([blob], fileName, { type: blob.type });

    // Crée un objet DataTransfer pour simuler l'ajout de fichiers
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    // Ajoute les fichiers existants dans l'input (s'il y en a)
    for (let i = 0; i < inputElement.files.length; i++) {
      dataTransfer.items.add(inputElement.files[i]);
    }

    // Assigne les fichiers à l'input
    inputElement.files = dataTransfer.files;
  }

  dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
  }

  setCurrentThumbnail(value) {
    setTimeout(function() {
      this.blanksSelfDesignGalleryThumbnailOutlets.forEach(function(outlet) {
        outlet.updateActiveState(value);
      });

      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        outlet.showCanvas(value);
      });
    }.bind(this), 0);
  }

  blanksSelfDesignCanvasOutletConnected(outlet, element) {
    if (!this.currentCanvas) {
      this.currentCanvas = outlet
    }
  }
}