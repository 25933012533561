import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    "blanks--self-design--gallery"
  ]

  static values = {
    url: String
  }

  connect() {
  }

  addArtwork(event) {
    this.blanksSelfDesignGalleryOutlet.addArtwork(this.urlValue)
  };
}
